@import "../../styles/responsive.scss";


.forgot-password-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;

  h3 {
    text-align: center;
    font-size: 25px;
    margin-bottom: 2rem;
    font-weight: 800;

    @include sm  {
        font-size: 20px !important;
    }
  }

  .card {
    border: 0;
    padding: 3rem !important;
    // margin-bottom: 3rem !important;
    max-width: 500px;
    margin-top: 12rem;
    margin-right: 3rem !important;
    margin-left: 3rem !important;
    // margin-top: -100px;
    align-items: center;
    background: hsla(0, 0%, 100%, 0.8);
    backdrop-filter: blur(30px);
    box-shadow: 0 2px 25px -5px rgba(0, 0, 0, 0.07),
      0 25px 21px -5px rgba(0, 0, 0, 0.04) !important;

      @include sm {
        max-width: 300px !important;
      }
  }

  .card-container {
    // position: absolute;
    max-width: 700px;

    // top: 100px;
    padding: 3rem;
    text-align: center;

    @include md {
      text-align: start;
      padding: 2rem !important;
    }
    @include sm {
      text-align: start;
      padding: 0rem !important;
    }

    h2 {
      font-weight: bold;
      margin-bottom: 3rem;

      @include sm {
        text-align: center;
        margin-bottom: 1.5rem !important;
      }
    }

    .btn-container {
      margin-top: 1rem;
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      @include sm {
        margin-top: 0rem !important;
      }

      button {
        height: 3rem;
        width: 6rem;
        font-size: 15px;
        @include md {
          padding: 0;
          height: 2rem;
          width: 4rem;
          font-size: 10px !important;
        }

        @include sm {
          height: 2.8rem !important;
          width: 6rem !important;
          font-size: 14px !important;
          margin: 0 !important;
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: space-between !important;
      margin-bottom: 0.5rem;
      margin-right: 1.5rem;
      // margin-left: 1rem;
      margin-top: 0;
    }

    .input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      .form-outline .form-control ~ .form-label {
        top: 5px;
      }
      .form-outline .form-control {
        padding-top: 0.8rem;
    
      }

      input {
        width: 300px;
        height: 45px;

        @include md {
          width: 250px;
        }
        @include sm {
          width: 200px;
        }
      }
    }
  }
}
