@import "../../styles/responsive.scss";

.mainOrderFormInput {
    
  .basicInformation {
    display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);

    @include md {
      grid-template-columns: repeat(1, 1fr);
    }

    .id {
      height: 50px;
    }
    .user {
      height: 50px;
    }
    .email {
      height: 50px;
    }
    .phone-number {
      height: 50px;
    }
  }

  .deviceInformaition {
    display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(3, 1fr);

    @include md {
      grid-template-columns: repeat(2, 1fr);
    }

    @include sm {
      grid-template-columns: repeat(1, 1fr);
    }


    .product {
      height: 50px;
      grid-column: span 3;

      @include md {
        grid-column: span 1;
      }
    }

    .variant {
      height: 50px;
      grid-column: span 1;

    }
    .warrenty {
      height: 50px;
      grid-column: span 1;
    }
    .device-color {
      height: 50px;
      grid-column: span 1;
    }
    .imei-1 {
      height: 50px;
      // margin-top: 20px;
      grid-column: span 1;
    }
    .imei-2 {
      height: 50px;
      grid-column: span 1;
    }
    
  }

}
