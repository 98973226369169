.orderPickur {

  .details {
    padding: 6px;
    margin: 0;
    color: #00b5ff !important;
    cursor: default;
    pointer-events: none;

    .detailText {
      cursor: pointer;
      pointer-events: auto;
    }
  }
  
}
