@import "../../../styles/responsive.scss";

/* orderTableStyles.css */
.orderTable .MuiTableRow-root {
  border-bottom: 1px solid #e0e0e0;
}
.orderTable .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0; /* Adjust the color and thickness as needed */
}

.orderTable .MuiTableHead-root {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
  background-color: #ffffff; /* Background color of the sticky header */
  border-bottom: 1.5px solid #b0b0b0; /* Optional: Add a bottom border */
}

.orderTable .dimmed {
  opacity: 0.5; /* Adjust opacity to dim the table data */
  pointer-events: none; /* Disable pointer events to prevent interaction while loading */
  transition: opacity 0.3s ease; /* Optional: Add a smooth transition effect */
}

.orderTable .loading-overlay {
  position: absolute;
  top: 45%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  @include sm {
    top: 65% !important;
    left: 52% !important;
  }
}

.orderTable {
  // margin-top: 50px;
  width: 1000px;

  .order-table-loading-container {
    position: absolute;
    top: 55%;
    left: 42%;
    transform: translate(-50%, -50%);
    z-index: 1000;

    @include sm {
      top: 65% !important;
      margin-left: 3rem !important;
    }
  }

  .error-container {
    margin: 17rem;

    @include sm {
      margin: 0;
    }
  }

  @include xxl {
    width: 750px;
  }

  @include xl {
    width: 600px;
  }

  @include lg {
    width: 550px;
  }

  @include md {
    width: 35%;
  }

  @include sm {
    margin-top: 0.2rem;
    width: 300px;
  }

  &:first-child {
    border-radius: 0.7rem !important;
  }

  .scrollable-content {
    height: 700px;
    overflow: auto;
    box-shadow: rgba(0 0 0 / 15%) 0px 0px 5px 0px;

    @include sm {
      height: 600px !important;
    }

    .MuiTableRow-root.MuiTableRow-head > * {
      font-weight: bold !important;
      border-bottom: 2px solid #b0b0b0;
    }

    .details {
      color: #00b5ff !important;
      cursor: default;
      pointer-events: none;

      .detailText {
        cursor: pointer;
        pointer-events: auto;
      }
    }

    tr th {
      font-size: 16px;
    }

    td,
    th {
      border: none !important;
    }

    tbody td,
    tbody thead {
      font-family: Arial;
      font-size: 15px;
    }

    thead tr {
      white-space: nowrap;
      overflow: hidden; /* Hide any overflowing content */
      text-overflow: ellipsis; /* Show an ellipsis (...) when content is truncated */
      max-width: 200px;
    }
    tbody tr {
      white-space: nowrap;
      overflow: hidden; /* Hide any overflowing content */
      text-overflow: ellipsis; /* Show an ellipsis (...) when content is truncated */
      /* max-width: 200px; */
      max-width: fit-content;
      margin: 10px;
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Adjust the thumb color as needed */
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Adjust the thumb color on hover as needed */
    }

    &::-webkit-scrollbar-track {
      background-color: transparent; /* Adjust the track color as needed */
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent; /* Hide the track piece at the start */
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent; /* Hide the track piece at the end */
    }
  }
}

.dimmed-button {
  opacity: 0.6;
  pointer-events: none;
  span {
    display: none;
  }
}
