@import "../../styles/responsive.scss";

.bars {
    display: none;

    @include sm {
        display: flex;
        position: fixed;
        margin-left: 30px;
        top: 1.5rem;
        z-index: 1001;
        padding: 7px;
        border: 1px solid #dfcccc;
        border-radius: 0.25rem;
        
    }
}

.Sidebar {
    display: flex;
    flex-direction: column;
    // position: relative;
    padding-top: 1rem;
    transition: all 300ms ease;

    @include sm {
        position: fixed;
        z-index: 1001;
        background:  #f3f3f3;
        width: 60%;
        height: 97%;
        
    }

    .logo {
        /* display: flex; */
        margin-left: 4rem;
        height: 2rem;
        font-weight: bold;
        font-size: 22px;
        gap: 1rem;
        align-items: center;
        justify-content: center;

        @include sm {
            margin-left: 5rem;
        }
       

        .logo-image{
            width: 3rem;
            height: 3rem;

            @include lg {
                display: none;
            }
            @include sm {
                height: 2rem;
                width: 2rem;
                margin-left: 10px;
                display: block;
            }
        }

        span {
            display: block;
            margin-left: -2rem;

            @include lg {
                display: none;
            }
            @include sm {
                display: block;
            }
        }
    }

    .menu {

        margin-top: 4rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include lg {
            width: max-content;
        }
        @include sm {
            margin-top: 3rem;
            gap: 1rem;
        }

        .menuItem {
            display: flex;
            align-items: center;
            gap: 1rem;
            height: 2.5rem;
            margin-left: 2rem;
            position: relative;
            transform: all 300ms ease;
            border-radius: 0.7rem;

           
            span {
                @include lg {
                    display: none;
                }

                @include sm {
                    display: block;
                }
            }

            &:last-child {
                // position: absolute;
                // bottom: 2.3rem;
                width: 100%;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .active {
            /* background: var(--activeItem); */
            background: rgb(147, 183, 221);
            margin-left: 0;

            @include lg {
                margin-left: 22px;
                width: 40px;
                align-items: center;
                justify-content: center;
            }

            @include sm {
                margin-left: 0;
                width: unset;
                align-items: center;
                justify-content: center;

                &:first-child {
                    padding-left: 30px;
                }
                &:nth-child(2) {
                    // Add your styles for the second child here
                    padding-left: 30px;
                }
                &:last-child {
                    padding-left: 30px;
                }
            }

            &::before {
                content: '';
                width: 8px;
                height: 100%;
                /* background: var(--pink); */
                background: rgb(147, 183, 221);
               
                margin-right: calc(1rem - 8px);

                @include lg {
                    content: none;
                    background: none;
                }

               
            }

            &::after {
                content:'';
                width: 10px;
                margin-left: calc(1rem - 10px);
            }

        }
    }

    .sign-out-container {
        cursor: pointer;
        position: relative;
        top: 4.3rem !important;
    }
}

