@import "../../../styles/responsive.scss";


.userTable {

    margin-top: 4.5rem;
    position: relative;
    margin-bottom: 2rem;
    
    .container {
        width: 90%;
        margin: 0;
        padding: 0;

        @include xxl {
            // width: 950px;
            width: 90%;
        }

        @include xl {
            width: 90%;
            position: absolute
            // width: calc(80% - 50px);
        }

        @include lg {
            width: 85%;
            position: absolute;
        }

        @include md {
            // width: 70%;
            width: 82%;
            position: absolute;
        }

        @include sm {
            width: 300px;
            position: relative;
            // margin-left: 20px;
            // margin-top: 10px;
        }
    }

    .spinner-container {
        margin: 20rem;
        @include sm {
            margin: 10rem;
        }
    }

    .error-container {
        margin: 17rem;

        @include sm {
            margin: 2rem;
        }
    }

    

    .scrollable-content {
        height: 500px;
        width: auto;
        overflow: auto;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
        border-radius: 10px;

            span {
                padding: 8px;
                border-radius: 9px;
            }

            .MuiTableRow-root.MuiTableRow-head > * {
                font-weight: bold !important;
            }

            .details {
                color: #00b5ff !important;
                cursor: default;
                pointer-events: none;
            }

            tr th {
                font-size: 16px;
            }
            
            td, th {
                border: none !important;
            }

            tbody td,
            tbody thead {
            //   padding: 10px;
              font-family: Arial;
              font-size: 15px;
            }
            
            
            thead tr {
              white-space: nowrap;
              overflow: hidden; /* Hide any overflowing content */
              text-overflow: ellipsis; /* Show an ellipsis (...) when content is truncated */
              max-width: 200px;
            }
            tbody tr {
              white-space: nowrap;
              overflow: hidden; /* Hide any overflowing content */
              text-overflow: ellipsis; /* Show an ellipsis (...) when content is truncated */
              max-width: fit-content;
              margin: 10px;
            
            }
        
    
        &::-webkit-scrollbar {
            height: 8px;
            width: 8px; /* Adjust the width as needed */
            background-color: transparent /* Adjust the background color as needed */
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #888; /* Adjust the thumb color as needed */
            border-radius: 4px;
          }
          
        &::-webkit-scrollbar-thumb:hover {
            background-color: #555; /* Adjust the thumb color on hover as needed */
        }
          
        &::-webkit-scrollbar-track {
            background-color: transparent; /* Adjust the track color as needed */
            border-radius: 4px;
        }
          
        &::-webkit-scrollbar-track-piece:start {
            background: transparent; /* Hide the track piece at the start */
        }
    
        &::-webkit-scrollbar-track-piece:end {
            background: transparent; /* Hide the track piece at the end */
        }
    }
}
