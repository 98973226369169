@import "../styles/responsive.scss";

:root {
  --yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
  --pink: #ff919d;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}
* {
  font-family: 'poppins',sans-serif;
  // width: 100% !important;

}

.App {
  background: linear-gradient(
    106.37deg,
    #ffe1bc 29.63%,
    #ffcfd1 51.55%,
    #f3c6f1 90.85%
  );
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;

  .AppGlass {
    display: grid;
    height: 97%;
    width: 97%;
    border-radius: 1.5rem;
    /* background-color: var(--glass); */
    background-color: white;
    overflow: hidden;
    // overflow: scroll;
    grid-template-columns: 11rem auto;
    /* grid-template-columns: minmax(12rem, 30%) 1fr; */
    gap: 20px;

    @include lg {
      grid-template-columns: 4rem auto;
      gap: 25px;
      overflow-y: scroll;
    }

    @include sm {
      grid-template-columns: 1fr;
      &::-webkit-scrollbar {
        display: none;
      }
    }

  }
}

.table-cell {
  padding: 6px;
  margin: 0;
}

.detail {
  color: #00b5ff !important;
  cursor: default;
  pointer-events: none;
}

/* order component style */

.custom-order-main {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1.5rem;
}

/* end here */
