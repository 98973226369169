@import "../../styles/responsive.scss";

.order-form-input {
  margin-top: 20px;

  .price-related-input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    .form-outline .form-control {
      height: 40px;
    }
    @include md {
      grid-template-columns: repeat(1, 1fr);
      gap: 0px !important;
      margin-bottom: 10px;
    }
  }

  .payment-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;

    @include md {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .basic-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;

    @include md {
      grid-template-columns: repeat(1, 1fr);
    }

    .user {
      @include sm {
        height: 50px;
      }
    }
    .phone-number {
      @include sm {
        height: 50px;
      }
    }

    .dob {
      @include sm {
        height: 50px;
      }
    }
  }

  .device-information {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);

    @include md {
      grid-template-columns: repeat(1, 1fr);
    }

    .product {
      // grid-column: span 2;

      @include md {
        grid-column: span 1;
      }

      @include sm {
        height: 50px;
      }
    }

    .imei {
      // margin-right: 10px;

      @include md {
        margin-right: 0;
      }

      @include sm {
        height: 50px;
      }
    }

    .variant {
      // margin-right: 10px;

      @include md {
        margin-right: 0;
      }

      @include sm {
        height: 50px;
      }
    }
  }

  .pickup-input {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);

    @include md {
      grid-template-columns: repeat(1, 1fr);
    }

    @include sm {
      gap: 0;
    }

    .city {
      @include sm {
        height: 50px;
      }
    }
    .state {
      @include sm {
        height: 50px;
      }
    }
  }
  .input-error {
    border: 0.7px solid #f5222d;
    border-radius: 2px;
  }
}
