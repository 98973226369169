@import "../../../styles/responsive.scss";

.card-container {

    @include sm {
        margin-left: 0;
    }
    
    .custom-card {
        display: flex;
        gap: 0.7rem;
        text-align: center;

        @include md {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        @include sm {
            display: flex;
            flex-direction: column;
            margin-left: 0;
            align-items: center;
            justify-content: center;
        }


        .ant-card {
            width: 390px;
            height: 140px;

            @include lg {
                width: 340px;
            }

            @include md {
                width: 400px;
            }

            @include sm {
                width: 300px;
            }

            
        }
    }
}
