@import "../../../styles/responsive.scss";

@media print {
  .order-view-table {
    display: flex;
    flex-direction: column;
    // margin: 0 auto;
    // padding: 20px;
    min-height: 100vh;
    box-sizing: border-box;

    .brand-container img {
      float: left;
      max-width: 200px;
    }

    .photo {
      max-width: 200px;
      max-height: 180px;
    }

    /* Adjust table styles for print */
    .customer-info table {
      width: 98%;

      border-collapse: collapse;
    }

    .customer-info th,
    .customer-info td {
      border: 1px solid black;
      padding: 8px;
    }

    .customer-info th {
      background-color: #f2f2f2;
    }

    .terms-conditions {
      margin-top: 30px;

      .sign {
        margin-top: 40px;
        margin-bottom: 20px;
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }

    .address {
      height: auto;
      margin-right: 20px;
      background: #fde21c;
      text-align: center;
      margin-top: 450px !important;
      padding: 5px;
      p {
        font-size: 16px;
      }
    }
  }
}

.order-view-table {
  margin-top: 4rem;
  display: flex;
  width: 1100px;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  @include xl {
    width: 1000px !important;
  }

  @include sm {
    margin-left: 5px !important;
    width: 360px !important;
    margin-top: 0rem !important;
  }

  .crm-info {
    margin-top: 0.5rem;
    overflow-y: auto;
    max-height: 70vh;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px 0px;

    @include sm {
      
      margin-top: 1rem !important;
    }
    .terms-conditions {
      margin-top: 10px;
      .terms {
        height: 2%;
        line-height: 26px;
        width: 50%;
        margin-top: 10px;

        border: 1px solid black;
      }

      .sign {
        margin-top: 40px;
        margin-bottom: 20px;
      }

      p {
        margin: 0;
        font-size: 16px;
      }
    }

    .address {
      height: auto;
      background: #fde21c;
      text-align: center;
      margin-top: 20px;
      padding: 5px;
      @include sm {
        padding: 2px;
      }
      p {
        font-size: 14px;
      }
      p:first-of-type {
      }
    }

    .crm-header {
      text-align: center;
      h2 {
        margin-top: 5px;
      }
    }
    .customer-info {
      margin-top: 10px;
      box-shadow: rgba(0 0 0 / 15%) 0px 0px 5px 0px;
      width: 100%;

      .customer-document {
        display: flex;
        flex-direction: column;
      }

      th,
      td {
        width: 33.33%; // Ensures equal width for each cell
        white-space: pre-line; // Allows text to wrap into multiple lines
        word-wrap: break-word; // Breaks long words into the next line
      }

      img {
        height: 180px;
        width: 200px;
      }
    }
  }

  // align-items: center;

  .brand-container {
    // margin-left: 25rem;
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    @include sm {
      justify-content: center !important;
      margin-top: 13px;
      margin-right: 5px;
      text-align: end;
    }
    .order-date {
      @include sm {
        display: none;
      }
    }
    img {
      height: 55px;
      width: 180px;

      @include sm {
        height: 40px !important;
        width: 120px !important;
      }
    }
  }
}

.crm-button {
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  margin-top: 10px;

  @include sm {
    align-items: center;
  }
}
