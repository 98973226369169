@import "../../styles/responsive.scss";

.customer-form-info {

    .customer-details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 0;

        @include lg {
            grid-template-columns: repeat(2, 1fr);
        }

        @include md {
            grid-template-columns: repeat(2, 1fr);
        }

        @include sm {
            grid-template-columns: repeat(1, 1fr);
        }

        .document-list {
            // margin-top: 15px;
            margin-right: 10px;

            @include lg {
                margin-top: 0;
            }

            @include md {
                grid-column: span 2;
            }

            @include sm {
                grid-column: span 1;
            }
        }

        .document-name {
            height: 43px;
            margin-right: 10px;
            @include md {
                // margin-bottom: 15px;
                margin-top: 10px;
                grid-column: span 1;
            }

            @include sm {
                grid-column: span 1;
            }
            
        }

        .document-image {

            input {
                max-width: 227px;
            }
            @include lg {
                grid-column: span 2;
                margin-bottom: 15px;
            }
            @include md {
                // margin-bottom: 15px;
                margin-top: 12px;
                grid-column: span 1;
                margin-right: 0;
            }

            @include sm {
                margin-top: 0;
                grid-column: span 1;
            }

        }
    }
}