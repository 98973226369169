@import '../../styles/responsive.scss';

.calender {
  .date-picker-container {
    display: flex;
    flex-direction: column;

    .css-v0pjtz-MuiStack-root>.MuiTextField-root {
      width: 250px;
      @include sm {
        width: 300px !important;
      }
    }

    .submit-button-container {
      button {
        @include sm {
          margin-top: 0rem !important;
        }
      }
    }

    .SG-datepicker__input-container input {

      width: 230px !important;
      height: 40px !important;

      @include xxl {
        width: 270px;
      }

      @include xl {
        width: 200px;
      }

      @include md {
        width: 350px;
      }

      @include sm {
        height: 60px !important;
        width: 280px !important;
      }
    }
  }
}
