@import "../../styles/responsive.scss";

.adminLogin {
  margin-left: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-image: url("https://cdn.suwalls.com/wallpapers/abstract/shades-of-blue-texture-48840-2880x1800.jpg");
  background: linear-gradient(#01356d,#075cb7,#01356d);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;

  .card {
    border: 0;
    padding: 3rem !important;
    // margin-bottom: 3rem !important;
    margin-right: 3rem !important;
    margin-left: 3rem !important;
    margin-top: 11rem;
    // margin-top: -100px;
    // align-items: center;
    // background: hsla(0, 0%, 100%, 0.8);
    background-color: white;
    backdrop-filter: blur(30px);
    box-shadow: 0 2px 25px -5px rgba(0, 0, 0, 0.07),
      0 25px 21px -5px rgba(0, 0, 0, 0.04) !important;

    @include sm {
      width: 300px;
    }
  }

  .card-body-container {
    padding: 3rem;
    text-align: center;
    .underline {
      position: relative;
      padding: 0;
      color: #fde21c;
      line-height: 1;
      letter-spacing: 0.3px;
      font-size: 34px;
      font-weight: 300;
      text-align: center;
      text-transform: none;
      margin-bottom: 50px;
      font-family: "poppins", sans-serif;

      &:before {
        content: "";
        width: 88px;
        height: 3.4px;
        background: #fde21c;
        position: absolute;
        border: 0.1px solid #002e5e;
        left: 57px;
        right: 0;
        margin: 0 auto;
        margin-top: -14px;
        border-radius: 2px;

        @include sm {
          margin-top: 4px !important;
        }
      }

      span {
        // font-weight: 700;
        padding-bottom: 5px;
        color: #2f2f2f;
      }
    }

    .login-style {
      font-family: poppins;
      color: #002e5e;
      font-weight: bold;
      text-align: center;
      font-size: 30px;
      margin-top: -74px;
      padding-bottom: 30px;

      @include sm {
        margin-top: -75px !important;
        padding-bottom: 18px !important;
        padding-top: 15px !important;
      }
    }

    .logo {
      width: 170px;
      height: 54px;
      margin-top: -120px;

      @include sm {
        width: 147px !important;
        height: 44px !important;
        margin-top: -73px !important;
      }
    }

    @include md {
      text-align: start;
      padding: 2rem !important;
    }
    @include sm {
      max-width: 400px !important;
      text-align: start;
      padding: 0rem !important;
    }

    h2 {
      font-weight: 800;
      margin-bottom: 3rem;

      @include sm {
        text-align: center;
        margin-bottom: 1.5rem !important;
      }
    }

    .btn-container {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 10px;

      @include sm {
        margin-top: 0rem !important;
        align-items: center !important;
      }

      button {
        height: 3rem;
        width: 6rem;
        background-color: #002e5e;

        @include md {
          padding: 0;
          height: 2rem;
          width: 4rem;
          font-size: 10px;
        }

        @include sm {
          height: 2.5rem !important;
          width: 6rem !important;
          font-size: 14px !important;
        }
      }
    }

    .checkbox-container {
      display: flex;
      justify-content: space-between !important;
      margin-top: 0.5;
      margin-bottom: 0.5rem;
      margin-right: 1.5rem;
      // margin-left: 1rem;
      margin-top: 0.6rem;
      @include sm {
        margin-top: 0.5rem !important;
        margin-left: 0rem !important;
      }

      @include md {
        flex-direction: column;
        gap: 5px;
        margin-left: 0;
        justify-content: flex-start;
      }
      p {
        @include md {
          margin-left: 12px;
        }
        @include sm {
          margin-top: 0px !important;
          margin-left: 0px !important;
        }
      }
    }

    .input-container {
      .form-outline .form-control ~ .form-label {
        top: 5px;
      }
      .form-outline .form-control {
        padding-top: 0.8rem;
      }
      .form-outline {
        width: 300px;
        height: 45px;
        @include sm {
          width: 200px !important;
        }
      }
      input {
        margin-bottom: 10px;

        @include md {
          width: 300px;
        }
        @include sm {
          width: 200px;
        }
      }
    }
  }
}

.dimmed-button {
  opacity: 0.6;
  pointer-events: none;
  span {
    display: none;
  }
}
