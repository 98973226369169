@import "../styles/responsive.scss";

.order-form {
  margin-top: 6rem;

  @include sm {
    margin-top: 3rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .device-title {
    margin-top: 20px;
    margin-bottom: 10px;

    h3 {
      @include sm {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .loading-container {
    margin: 20rem;

    @include sm {
      margin: 15rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 11rem !important;
    }
  }

  .error-container {
    margin: 17rem;

    @include sm {
      margin: 2rem;
      margin-top: 15rem;
    }
  }

  .card {

    max-height: 700px;
    width: 90%;
    overflow: auto;
    padding: 1.5rem;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
    @include sm {
      padding: 0.5 rem !important;
      max-width: 340px !important;
      max-height: 600px !important;
      // width: auto !important;
    }
    
    .update-btton-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-top: 1rem;
      button {
        margin-top: 0px;
      }
    }
    &::-webkit-scrollbar {
      width: 5px; /* Adjust the width as needed */
      height: 0∆;
      background-color: transparent; /* Adjust the background color as needed */
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Adjust the thumb color as needed */
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Adjust the thumb color on hover as needed */
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
      // background-color: #f1f1f1; 
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-track-piece:end {
      background: transparent; /* Hide the track piece at the end */
    }
  
    &:-webkit-scrollbar-track-piece:start {
      background: transparent; /* Hide the track piece at the start */
    }

  }

  
}

