@import "../../styles/responsive.scss";


.order-container {
    display: flex;
    margin-left: 0;
    margin-top: 6rem;
    margin-bottom: 2rem;
    gap: 20px;

    @include sm {
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse !important;
        margin-top: 4.3rem !important;
    }

    .order-table-container {
        flex: 1;

    }
    .filter-container {
        flex: 1;
        
    }
    @include md {
      
        display: flex;
        flex-direction: column;
    }
}




