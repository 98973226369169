@import "../../../styles/responsive.scss";


.search-bar {
    .searchInput {
        display: flex;
        gap: 10px;

        .searchBtn {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 2rem;
            width: 5rem;
            font-size: 0.7rem;
        }
    }
}