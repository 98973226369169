@import "../../styles/responsive.scss";

.filterbar {
  display: flex;
  flex-direction: column;
  // margin-left: 30px;
  // margin-top: 3rem;
  gap: 20px;
  height: 520px;
  @include md {
    margin: 0;
    margin-top: 2rem;
  }
  @include sm {
    margin-top: 0rem !important;
    height: 238px !important;
  }

  .scrollable-content {
    width: 235px;
    height: 328px;
    padding: 5px;
    overflow: auto;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);

    @include xl {
      max-width: 233px;
    }

    @include md {
      max-width: 350px;
    }

    @include sm {
      max-width: 300px;
    }

    .MuiTableRow-root.MuiTableRow-head > * {
      font-weight: bold !important;
    }

    tr th {
      font-size: 16px;
    }

    td,
    th {
      border: none !important;
    }

    tbody td,
    tbody thead {
      font-family: Arial;
      font-size: 15px;
    }

    thead tr {
      white-space: nowrap;
      overflow: hidden; /* Hide any overflowing content */
      text-overflow: ellipsis; /* Show an ellipsis (...) when content is truncated */
      max-width: 200px;
    }
    tbody tr {
      white-space: nowrap;
      overflow: hidden; /* Hide any overflowing content */
      text-overflow: ellipsis; /* Show an ellipsis (...) when content is truncated */
      /* max-width: 200px; */
      max-width: fit-content;
      margin: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px; /* Adjust the width as needed */
      background-color: transparent; /* Adjust the background color as needed */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Adjust the thumb color as needed */
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Adjust the thumb color on hover as needed */
    }

    &::-webkit-scrollbar-track {
      background-color: transparent; /* Adjust the track color as needed */
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track-piece:start {
      background: transparent; /* Hide the track piece at the start */
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent; /* Hide the track piece at the end */
    }
  }
}
