@import "../../../styles/responsive.scss";



.mainTable .dimmed {
  opacity: 0.5; /* Adjust opacity to dim the table data */
  pointer-events: none; /* Disable pointer events to prevent interaction while loading */
  transition: opacity 0.3s ease; /* Optional: Add a smooth transition effect */
}

.mainTable .loading-overlay {
  position: absolute;
  top: 45%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  @include sm {
    top: 50% !important;
    left: 52% !important;
  }
}

.mainTable .MuiTableRow-root {
  border-bottom: 1px solid #e0e0e0;
}
.mainTable .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0; /* Adjust the color and thickness as needed */
}

.mainTable .MuiTableHead-root {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it's above other content */
  background-color: #ffffff; /* Background color of the sticky header */
  border-bottom: 1.5px solid #b0b0b0; /* Optional: Add a bottom border */
}



.mainTable {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  @include sm {
    margin-top: 0rem !important;
    margin-left: 0;
    justify-content: center;
    align-items: center;
  }
  .pagination-container {
    margin: 5px;

    @include sm {
      width: 100% !important;
      margin: 5px !important;
    }
  }

  tr th {
    font-size: 16px;
  }

  td,
  th {
    border: none !important;
  }

  &:first-child {
    border-radius: 0.7rem !important;
  }

  tbody td,
  tbody thead {
    font-family: Arial;
    font-size: 14px;
  }

  thead tr {
    white-space: nowrap;
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis; /* Show an ellipsis (...) when content is truncated */
    max-width: 200px;
  }
  tbody tr {
    white-space: nowrap;
    overflow: hidden; /* Hide any overflowing content */
    text-overflow: ellipsis; /* Show an ellipsis (...) when content is truncated */
    /* max-width: 200px; */
    max-width: fit-content;
    margin: 10px;
  }

  .search {
    display: inline-flex;
    gap: 20rem;

    h4 .ant-typography {
      @include sm {
        margin: 0;
      }
    }

    @include lg {
      gap: 14rem;
      display: flex;
    }

    @include md {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 400px;
    }

    @include sm {
      width: 300px;
    }
  }
  .scrollable-content {
    height: 550px;
    max-width: 1100px;
    overflow: auto;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);

    @include xxl {
      max-width: 1000px !important;
    }

    @include xl {
      max-width: 900px !important;
    }
    @include lg {
      max-width: 700px !important;
    }
    @include md {
      max-width: 500px !important;
    }

    @include sm {
      height: 500px !important;
      max-width: 300px !important;
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px; /* Adjust the width as needed */
      background-color: transparent;
      // background-color: #f1f1f1; /* Adjust the background color as needed */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888; /* Adjust the thumb color as needed */
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* Adjust the thumb color on hover as needed */
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      // background-color: #f1f1f1; /* Adjust the track color as needed */
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track-piece:end {
      background: transparent; /* Hide the track piece at the end */
    }

    &:-webkit-scrollbar-track-piece:start {
      background: transparent; /* Hide the track piece at the start */
    }

    .details {
      color: #00b5ff !important;
      cursor: default;
      pointer-events: none;

      .detailText {
        cursor: pointer;
        pointer-events: auto;
      }
    }
  }

  .error-container {
    margin: 10rem;

    @include sm {
      margin: 2rem;
    }
  }
  .spinner {
    position: absolute;
    margin-left: 20rem;
    top: 50%;
    left: 10%;
    transform: translate(-50%, -50%);
    z-index: 1000;

    @include sm {
      // margin: 10rem;
      margin-left: 8rem !important;
      margin-top: 1rem;
      margin-bottom: 4rem;
    }
  }
}
