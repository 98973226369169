@import "../../styles/responsive.scss";

.MainDash {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-left: 0;
  @include sm {
    width: 100%;
    margin-left: 0;
    margin-top: 3rem !important;
    align-items: center;
    justify-content: center;
  }

  @include lg {
    // position: absolute;
  }
}
