@import "../../styles/responsive.scss";

.customer-form-info {

  .signature-section {
    .signature {

      .sigCanvas {
        width: 500px;
        height: 200px;

        @include sm {
          width: 280px !important;
        }
      }
      
      button {
        // margin-left: 4rem;
        height: 50px;
        width: 180px;
      }
    }
  }
  .customer-details {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;

    @include lg {
      grid-template-columns: repeat(2, 1fr);
    }

    @include md {
      grid-template-columns: repeat(2, 1fr);
    }

    @include sm {
      grid-template-columns: repeat(1, 1fr);
    }

    .document-list {
      // margin-top: 15px;
      margin-right: 10px;
      margin-bottom: 5px;

      @include lg {
        margin-top: 0;
      }

      @include md {
        grid-column: span 2;
      }

      @include sm {
        grid-column: span 1;
      }
    }

    .document-image {
      input {
        max-width: 227px;
      }
      @include lg {
        grid-column: span 2;
        margin-bottom: 15px;
      }
      @include md {
        // margin-bottom: 15px;
        margin-top: 12px;
        grid-column: span 1;
        margin-right: 0;
      }

      @include sm {
        margin-top: 0;
        grid-column: span 1;
      }
    }
  }

  .aadhaar-details {
    .aadhaar-image {
      label {
        font-weight: 800;
        margin-right: 10px;
      }
      input {
        margin-bottom: 10px;
        @include sm {
          margin-bottom: 10px;
        }
      }
      .error-message {
        color: red;
      }
    }
  }
}
